import React from "react";
import ParkingFinderForm from "../components/ParkingFinderForm";
import Layout from "../components/basics/Layout";

function Accueil() {
  return (
    <Layout isBg={true}>
      <ParkingFinderForm />
    </Layout>
  );
}

export default Accueil;
