import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { Accueil, Checkout, EditPayment, LoginForm } from "./pages/indexPages";
import ParkingCard from "./pages/ParkingCard";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<LoginForm />} />
      <Route path="/" element={<Accueil />} />
      <Route path="/price" element={<ParkingCard />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/edit/:ticketNumber" element={<EditPayment />} />
      <Route path="/edit" element={<EditPayment />} />

      {/* Ajoutez ici les autres routes pour les nouvelles pages */}
    </Routes>
  );
}

export default App;
