import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faChevronCircleRight,
  faInfoCircle
} from "@fortawesome/free-solid-svg-icons";
import "../CSS/ParkingCard.css";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Layout from "../components/basics/Layout";

function ParkingCard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { startDate, arrivalTime } = location.state || {}; // Extraire startDate et arrivalTime
  const [validTillDate, setValidTillDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [price, setPrice] = useState(20.70);
  const [show, setShow] = useState(false);

  // Calculer la date valide jusqu'à (+1 jour) au chargement du composant
  useEffect(() => {
    if (startDate) {
      const newDate = new Date(startDate);
      newDate.setDate(newDate.getDate() + 1); // Ajouter 1 jour
      setEndDate(newDate);
      setValidTillDate(
        newDate.toLocaleString("fr-FR", { dateStyle: "medium" })
      );
    }
  }, [startDate]);

  const formattedStartDate = startDate
    ? new Date(startDate).toLocaleString("fr-FR", { dateStyle: "medium" })
    : "Date non sélectionnée";
    

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const generateParkingInfo = (startDate) => {
    const beginDate = new Date(startDate);
    const endDate = new Date(beginDate);
    endDate.setDate(beginDate.getDate() + 1);
    beginDate.setHours(8, 0, 0); // Set start time to 8:00 AM
    endDate.setHours(8, 0, 0); // Set end time to 8:00 AM the next day

    return {
      id: 1,
      nom: "1000 De La Gauchetière",
      adresse: "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5",
      imageLink:
        "https://img.lapresse.ca/924x615/201705/12/1401641-caisse-depot-mettra-bientot-vente.jpg",
      prix: price,
      startDate: beginDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      endDate: endDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      duree: "24 heures",
      valide: endDate.toLocaleString("fr-FR", {
        dateStyle: "medium",
        timeStyle: "short",
      }),
      lat: 45.49817526069414,
      lng: -73.56637633437992,
    };
  };

  const parking = generateParkingInfo(startDate);
  /* console.log(parking); */

  const goCheckout = (parkingId) => {
    navigate(`/checkout`, { state: { price, startDate, arrivalTime, endDate } });
  };

  return (
    <Layout>
      <div className="w-full lg:px-14 lg:py-5 pb-20">
        <button
          onClick={() => {
            navigate("/");
          }}
          className="flex items-center  w-full justify-start lg:mb-5"
        >
          <div className="bg-customblue text-white text-xl px-2 rounded-full">{`<`}</div>
        </button>
        <div className="flex flex-col lg:flex-row lg:space-x-10 w-full ">
          <img
            className="lg:w-full mt-3"
            src={parking.imageLink}
            alt={parking.nom}
          />
          <div className="flex flex-col space-y-4 lg:space-y-10 w-full">
            <div className="flex items-end justify-between  mt-4">
              <h5 className="font-semibold text-2xl ">{parking.nom}</h5>
              <button className="text-customblue" onClick={handleShow}>
                <FontAwesomeIcon icon={faInfoCircle} size="xl" />
              </button>
            </div>
            <span className="text-4xl font-bold">
              {parseFloat(parking.prix).toFixed(2)}$
            </span>
            <div className="text-lg">
              <p>
                <strong>{t("home:ParkingCard:dateAndHour")} </strong>{" "}
                {formattedStartDate}, {arrivalTime}
              </p>
              <p>
                <strong>{t("home:ParkingCard:validTill")}</strong>{" "}
                {validTillDate}, {arrivalTime}
              </p>
              <p>
                <strong>{t("home:ParkingCard:duration")}</strong>{" "}
                {parking.duree}
              </p>
            </div>
            <div className="text-lg">
              <p>{parking.adresse}</p>
              <a href="https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu">
                {t("home:ParkingCard:seeOnMap")}{" "}
                <FontAwesomeIcon icon={faArrowRight} />
              </a>
            </div>
            <button
              className="bg-customblue text-white py-4 px-4 rounded-md text-xl flex space-x-3 uppercase font-semibold  items-center justify-center mt-4 lg:w-6/12"
              onClick={() => goCheckout(parking.id)}
            >
              <span>{t("home:ParkingCard:prepay")}</span>
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("home:ParkingCard:moreInformation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-customblue bleu-pema-fonce"
            onClick={handleClose}
          >
            {t("home:ParkingCard:close")}
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}

export default ParkingCard;
