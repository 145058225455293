import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "../CSS/ParkingFinderForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ParkingFinderForm() {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [arrivalTime, setArrivalTime] = useState("08:00");
  const navigate = useNavigate();

  // Générer les options d'heures d'arrivé
  const generateTimeOptions = () => {
    const times = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour24 = h.toString().padStart(2, "0"); // Format 24h (pour la valeur)
        const minute = m.toString().padStart(2, "0");

        // Format 12h pour l'affichage
        const hour12 = h % 12 || 12;
        const ampm = h < 12 ? "AM" : "PM";
        const display = `${hour12}h${minute} ${ampm}`;
        const value = `${hour24}:${minute}`;

        // Pousser l'objet avec display et value
        times.push({ display, value });
      }
    }
    return times;
  };

  // Mettre à jour la date de début avec l'heure d'arrivée sélectionnée
  const updateStartDateWithTime = () => {
    const [hours, minutes] = arrivalTime.split(":").map(Number);
    const newDate = new Date(startDate);
    newDate.setHours(hours, minutes, 0, 0);

    return newDate;
  };

  // Redirection vers la page avec les informations du parking
  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedStartDate = updateStartDateWithTime();
    console.log(arrivalTime);

    navigate("/price", { state: { startDate: updatedStartDate, arrivalTime } }); // Envoyer la date d'entré et l'heure d'arrivée à la page price
  };

  return (
    <div className={`w-full pt-10 pb-7 sm:pb-10  lg:px-14 overflow-hidden`}>
      <div className=" flex flex-col space-y-5 w-full pb-7">
        <h2 className="font-bold uppercase text-3xl">
          {t("home:ParkingFinderForm:formTitle")}
        </h2>
        <p className="font-semibold mt-3 text-lg">
          <FontAwesomeIcon icon={faMapMarkerAlt} /> 1000 de la Gauchetière,
          Montréal
        </p>

        <form
          className="pt-10 w-full flex flex-col max-w-[500px] lg:max-w-[800px]"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col w-full">
            <label
              htmlFor="beginDate"
              className="text-customblue font-semibold pb-2 text-lg"
            >
              {t("home:ParkingFinderForm:entryDate")}
            </label>

            <DatePicker
              id="beginDate"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              locale={fr}
              dateFormat="dd/MM/yyyy"
              className="py-3 px-4 border border-gray-300 rounded-md w-10/12 lg:w-full focus:outline-none focus:ring-2 focus:ring-customblue focus:border-transparent"
              minDate={new Date()} // Bloque les dates avant aujourd'hui
              placeholderText={t("home:ParkingFinderForm:selectDate")}
              showPopperArrow={false}
            />
          </div>

          <div className="flex flex-col mt-4 w-full">
            <label
              htmlFor="arrivalTime"
              className="text-customblue font-semibold pb-2 text-lg"
            >
              {t("home:ParkingFinderForm:arrivalTime")}
            </label>
            <select
              id="arrivalTime"
              value={arrivalTime}
              onChange={(e) => setArrivalTime(e.target.value)}
              className="py-3 px-3 border border-gray-300 shadow-none bg-white rounded-md w-10/12 lg:w-full focus:outline-none focus:ring-2 focus:ring-customblue focus:border-transparent max-h-40"
            >
              {generateTimeOptions().map((time) => (
                <option key={time.value} value={time.value}>
                  {time.display} {/* Afficher en format 12h AM/PM */}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col mt-4 w-full">
            <label
              htmlFor="promoCode"
              className="text-customblue font-semibold pb-2 text-lg"
            >
              {t("home:ParkingFinderForm:promoCode")}
            </label>
            <input
              type="text"
              className="py-3 px-3 border border-gray-300 rounded-md w-10/12 lg:w-full"
              id="promoCode"
              placeholder={t("home:ParkingFinderForm:promoCodePlaceholder")}
            />
          </div>

          <button
            type="submit"
            className="bg-customblue text-white py-4 px-4 mt-5 rounded-md text-lg flex space-x-5 items-center lg:w-6/12 justify-center"
          >
            <span>{t("home:ParkingFinderForm:btnGetPrice")}</span>
            <FontAwesomeIcon icon={faChevronCircleRight} size={"1.2x"} />
          </button>
          <Link
            to="/edit"
            className="bg-customblue text-white py-4 px-4 mt-4 lg:mt-5 rounded-md text-lg flex space-x-5 items-center lg:w-6/12 justify-center"
          >
            <span className="no-underline">{t("home:Navbar:myParking")}</span>
          </Link>
        </form>
      </div>
    </div>
  );
}

export default ParkingFinderForm;
