import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../CSS/ParkingTicket.css";

function ParkingTicket({ parking }) {
  /* const mapSrc = `https://www.google.com/maps?q=${parking.lat},${parking.lng}&hl=es;z=14&output=embed`; */ // En vue d'avoir plusieurs parking sur la meme plateforme !

  return (
    <div className="lg:m-4 m-2 rounded-xl shadow-2xl mb-20 overflow-hidden xl:w-4/12 d-flex flex-col justify-between max-w-[400px]">
      <div className="ticket-header bg-customblue d-flex justify-content-between align-items-center">
        <span className="font-semibold text-lg">Billet Stationnement</span>
        <svg
          width="80"
          height="70"
          viewBox="0 0 500 500"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="ticket-logo"
        >
          <path
            d="M500 250.003C500 388.073 388.071 500 250 500C111.929 500 0 388.073 0 250.003C0 111.934 111.929 0.000235209 250 0.000235209C388.071 0.000235209 500 111.934 500 250.003Z"
            fill="#003595"
          />
          <path
            d="M296.064 105.697H160.107V155.809H226.43V155.993H282.733C300.001 155.993 328.909 155.993 328.909 205.174C328.909 253.957 300.001 253.957 282.733 253.957H207.947V253.906H160.107V395.071H210.53V304.24H296.064C346.278 304.24 378.724 265.357 378.724 205.174C378.724 145.675 345.505 105.697 296.064 105.697Z"
            fill="white"
          />
          <path
            d="M250.862 226.114H160.1V183.61H250.862V226.114Z"
            fill="#00AEEF"
          />
        </svg>
      </div>

      <div className="flex flex-col space-y-4 px-4">
        <div className="ticket-map mt-1 d-flex justify-content-center mb-2">
          <img
            src="https://i.pinimg.com/736x/a8/69/40/a86940a4ed8a69539b341f3c414c47b3.jpg"
            alt=""
            className="w-75"
          />
        </div>
        <h5 className="text-2xl text-customblue font-bold">{parking.name}</h5>

        {/* Date et heure */}
        <div className="flex flex-col space-y-2">
          <div className="date-time ">
            Du
            <FontAwesomeIcon icon={faCalendarAlt} />
            <span>
              {parking.beginDate}, {parking.startTime}
            </span>
          </div>
          <div className="date-time">
            Au
            <FontAwesomeIcon icon={faCalendarAlt} />{" "}
            <span>
              {parking.endDate}, {parking.startTime}
            </span>
          </div>
        </div>

        {/* Prix */}
        <div className="pricing my-3">
          <div className="price-info">
            {/* <FontAwesomeIcon icon={faMoneyBillWave} /> */}{" "}
            <span>Sous-Total: 18.00$</span>
          </div>
          <div className="taxes">
            <span>Taxes: 2.70$</span>
          </div>
          <div className="total">
            <strong>Total: 20.70$</strong>
          </div>
        </div>

        {/* Adresse */}
        <div className="address my-3 font-semibold">
          <span>{parking.location}</span>
        </div>

        <div className="w-full ticket-map">
          <a
            className="pb-4 block"
            href="https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.499409,-73.5664085,16.42z/data=!4m15!1m8!3m7!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!2s1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5!3b1!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu"
          >
            Voir sur la carte <FontAwesomeIcon icon={faArrowRight} />
          </a>
          {/* <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4190.060594249761!2d-73.56640848456246!3d45.49940904335167!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc91a5d1e3fb39d%3A0x7934c4a99504768b!2s1000%20De%20La%20Gaucheti%C3%A8re%2C%201000%20Rue%20De%20la%20Gaucheti%C3%A8re%20O%2C%20Montr%C3%A9al%2C%20QC%20H3B%204W5!5e0!3m2!1sfr!2sca!4v1715889509995!5m2!1sfr!2sca"
            width="100%"
            height="350"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe> */}
        </div>
      </div>

      <div className="ticket-footer text-center">
        <small>Scanner ce code QR à la borne de sortie</small>
      </div>
    </div>
  );
}

export default ParkingTicket;
