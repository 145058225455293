import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import {
  faCar,
  faQrcode,
  faMapMarkerAlt,
  faTimesCircle,
  faClock,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Modal, Button } from "reactstrap";
import "../CSS/EditPayment.css";
import Layout from "../components/basics/Layout";

function EditPayment() {
  const { ticketNumber: paramTicketNumber } = useParams();
  const navigate = useNavigate();
  const [modal, setModal] = useState(true);
  const [ticketNumber, setTicketNumber] = useState(paramTicketNumber || "");
  const [ticketInfo, setTicketInfo] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [percentage, setPercentage] = useState(100);
  const [ticketStatus, setTicketStatus] = useState("");
  const [showQrCode, setShowQrCode] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };
  const toggleQrCode = () => {
    setShowQrCode(!showQrCode);
  };

  const handleTicketNumberSubmit = async () => {
    if (!ticketNumber) return;

    try {
      const response = await axios.get(
        /* `http://localhost:3002/api/v1/ticket/${ticketNumber}` */
        `https://backend-pema.vercel.app/api/v1/ticket/${ticketNumber}`,
      );

      if (response.status === 201) {
        setTicketInfo(response.data);
        calculateTimeLeft(response.data.beginDate, response.data.endDate);
        setModal(false); // Fermer le modal
        navigate(`/edit/${response.data.ticketNumber}`);
      } else {
        console.error("Failed to fetch ticket information:", response.data);
      }
    } catch (error) {
      console.error("Error fetching ticket information:", error);
    }
  };

  // Fonction pour calculer la durée restante et le pourcentage de temps restant
  const calculateTimeLeft = (beginDate, endDate) => {
    const arriveTime = new Date(beginDate);
    const exitTime = new Date(endDate);
    const currentTime = new Date();

    if (currentTime < arriveTime) {
      // Ticket pas encore actif
      setTicketStatus("inactive");
      setTimeLeft(0);
      setPercentage(0);
    } else if (currentTime >= arriveTime && currentTime <= exitTime) {
      // Ticket actif
      const totalTime = exitTime - arriveTime;
      const currentTimeLeft = exitTime - currentTime;

      setTicketStatus("active");
      setTimeLeft(currentTimeLeft > 0 ? currentTimeLeft : 0);
      setPercentage(
        currentTimeLeft > 0 ? (currentTimeLeft / totalTime) * 100 : 0,
      );
    } else {
      // Ticket expiré
      setTicketStatus("expired");
      setTimeLeft(0);
      setPercentage(0);
    }
  };

  // Effect pour vérifier si un ticketNumber est présent dans l'URL
  useEffect(() => {
    if (paramTicketNumber) {
      handleTicketNumberSubmit();
    } else {
      setModal(true);
    }
  }, [paramTicketNumber]);

  // Mise à jour du compte à rebours et de la barre de progression
  useEffect(() => {
    if (!ticketInfo) return;

    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const updatedTimeLeft = prevTimeLeft - 1000;
        if (updatedTimeLeft < 0) {
          clearInterval(interval);
          return 0;
        }
        setPercentage(
          ticketInfo
            ? (updatedTimeLeft /
                (new Date(ticketInfo.endDate) -
                  new Date(ticketInfo.beginDate))) *
                100
            : 0,
        );
        return updatedTimeLeft;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [ticketInfo]);

  // Formatter les dates en français
  const dateFormatter = new Intl.DateTimeFormat("fr-FR", {
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // Affichage du temps restant dans le format hh:mm:ss
  const formatTimeLeft = (time) => {
    const hours = Math.floor(time / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const formattedTimeLeft = formatTimeLeft(timeLeft);

  return (
    <Layout>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Saisir le numéro du ticket de parking
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={toggleModal}
          ></button>
        </div>
        <div className="modal-body">
          <input
            type="text"
            className="form-control"
            placeholder="Numéro du billet"
            value={ticketNumber}
            onChange={(e) => setTicketNumber(e.target.value)}
          />
        </div>
        <div className="modal-footer">
          <Button color="customblue" onClick={handleTicketNumberSubmit}>
            Soumettre
          </Button>
        </div>
      </Modal>
      {!modal && ticketInfo && (
        <div className="text-center pt-5 lg:px-0 px-4 flex flex-col items-center justify-center space-y-4">
          <h3>Session de stationnement</h3>
          <div className="pb-5">
            {/* Afficher le compte à rebours ou un message approprié en fonction du statut du ticket */}
            {ticketStatus === "active" ? (
              <>
                <div
                  style={{ width: "200px", height: "210px", margin: "auto" }}
                  className="progress-bar-container mb-3"
                >
                  <CircularProgressbar
                    value={percentage}
                    styles={buildStyles({
                      pathColor: `rgba(0, 53, 149, ${percentage / 100})`,
                      textColor: "#4e4e4e",
                      trailColor: "#009fe3",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                  <img
                    src="https://png.pngtree.com/png-vector/20230408/ourmid/pngtree-white-car-top-view-vector-png-image_6681668.png"
                    alt=""
                    className="car-icon"
                  />
                </div>
                <h2 className="timer mt-3">{formattedTimeLeft}</h2>
                <p>Durée restante</p>
              </>
            ) : ticketStatus === "inactive" ? (
              <div className="status-message-container inactive-status">
                <FontAwesomeIcon icon={faClock} className="status-icon" />
                <p className="status-message">
                  <strong>
                    Votre session de stationnement n'est pas encore active.
                  </strong>
                  <br />
                  Elle commencera le{" "}
                  <span className="highlight">
                    {ticketInfo && ticketInfo.beginDate
                      ? dateFormatter.format(new Date(ticketInfo.beginDate))
                      : "Date invalide"}
                  </span>
                  .
                </p>
              </div>
            ) : (
              <div className="status-message-container expired-status">
                <FontAwesomeIcon icon={faTimesCircle} className="status-icon" />
                <p className="status-message">
                  <strong>Votre session de stationnement est terminée.</strong>
                  <br />
                  <span className="highlight">
                    Merci d'avoir utilisé nos services !
                  </span>
                </p>
              </div>
            )}

            {/* Reste des détails de la session */}
            <div className="session-details">
              <div className="d-flex justify-content-center">
                <p className="slot">
                  Numéro de ticket : {ticketInfo.ticketNumber}
                </p>
              </div>
              <div className="d-flex justify-content-between my-2">
                <p>
                  <span className="label">Arrivée</span> <br />
                  {ticketInfo && ticketInfo.beginDate
                    ? dateFormatter.format(new Date(ticketInfo.beginDate))
                    : "Date invalide"}
                </p>
                <p>
                  <span className="label">Départ</span> <br />
                  {ticketInfo && ticketInfo.endDate
                    ? dateFormatter.format(new Date(ticketInfo.endDate))
                    : "Date invalide"}
                </p>
              </div>
              <p>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> Adresse:{" "}
                {ticketInfo.location ||
                  "1000 Rue De la Gauchetière O, Montréal, QC H3B 4W5"}
              </p>
            </div>
            <button className="bg-customblue text-white py-4 px-4 rounded-md text-lg flex space-x-5 items-center lg:w-full justify-center w-full mx-auto mb-2">
              <span className="w-full">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.google.com/maps/place/1000+De+La+Gaucheti%C3%A8re,+1000+Rue+De+la+Gaucheti%C3%A8re+O,+Montr%C3%A9al,+QC+H3B+4W5/@45.4981757,-73.5689608,17z/data=!3m1!4b1!4m6!3m5!1s0x4cc91a5d1e3fb39d:0x7934c4a99504768b!8m2!3d45.4981757!4d-73.5663859!16zL20vMDRrbHhr?entry=ttu&g_ep=EgoyMDI0MTEwNi4wIKXMDSoASAFQAw%3D%3D"
                >
                  Localiser mon stationnement
                </a>
              </span>
              <FontAwesomeIcon icon={faCar} />
            </button>
            <button
              className="bg-customblue text-white py-4 px-4 rounded-md text-lg flex space-x-5 items-center lg:w-full justify-center w-full mx-auto"
              onClick={toggleQrCode}
            >
              <span className="w-full">QR Code</span>
              <FontAwesomeIcon icon={faQrcode} />
            </button>
          </div>
          {showQrCode ? (
            <div className="fixed h-[100vh] w-[100vw] top-0 left-0 flex justify-center items-start pt-[160px] bg-[#000000b5]">
              <div className="max-w-3xl flex justify-center items-center flex-col relative">
                <button
                  className="text-white text-5xl bg-customblue rounded-full p-2 w-[60px] h-[60px] flex justify-center items-center absolute top-[-80px] lg:right-[-80px] right-[-20px] hover:cursor-pointer"
                  onClick={toggleQrCode}
                >
                  <FontAwesomeIcon icon={faClose} />
                </button>
                <img
                  src={ticketInfo.qrCode}
                  alt=""
                  className="w-full max-w-[400px] min-w-[300px]"
                />
                <button
                  className="bg-customblue text-white py-4 px-4 rounded-md text-lg flex space-x-5 items-center lg:w-full justify-center w-full mx-auto mt-6"
                  onClick={toggleQrCode}
                >
                  <span>Fermer</span>
                  <FontAwesomeIcon icon={faClose} />
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </Layout>
  );
}

export default EditPayment;
