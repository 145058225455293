import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../../CSS/Navbar.css";
import pemalogo from "../../img/pemalogo.svg";

function NavBar({ isWhite }) {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div>
      <nav
        className={`bg-white fixed top-0 py-3 px-4 z-10 w-full flex items-center justify-between`}
      >
        <Link to="/" onClick={() => setExpanded(false)}>
          <img className="h-14" src={pemalogo} alt="Description" />
        </Link>
        <button
          type="button"
          onClick={() => setExpanded(expanded ? !expanded : "expanded")}
        >
          <span className=" text-lg font-extrabold text-blue-800 uppercase">
            Menu
          </span>
        </button>
      </nav>
      <div
        className={`w-full h-96 bg-red-400 fixed pt-24 px-4 top-0 z-0 ${expanded ? "translate-y-0" : "-translate-y-full"} transition transform duration-300 ease-in-out`}
      >
        <Link className="dropdown-item" to="/edit">
          {t("home:Navbar:myParking")}
        </Link>
        {i18n.language !== "en" && (
          <button
            className="nav-link"
            href="#"
            onClick={() => changeLanguage("en")}
          >
            En
          </button>
        )}
        {i18n.language !== "fr" && (
          <button
            className="nav-link"
            href="#"
            onClick={() => changeLanguage("fr")}
          >
            Fr
          </button>
        )}

        {/* <Link
          className="nav-link"
          to="/"
          onClick={() => scrollToElement("service")}
        >
          {t("home:Navbar:item2")}
        </Link>
        <ul className="navbar-nav">
          {i18n.language !== "en" && (
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => changeLanguage("en")}
              >
                En
              </a>
            </li>
          )}
          {i18n.language !== "fr" && (
            <li className="nav-item">
              <a
                className="nav-link"
                href="#"
                onClick={() => changeLanguage("fr")}
              >
                Fr
              </a>
            </li>
          )}
        </ul> */}
      </div>
    </div>
  );
}

export default NavBar;

/*     <nav className={`navbar navbar-expand-lg justify-content-between ${isWhite ? "bg-white" : "bg-transparent"} fixed-top px-lg-5`}>
      <div className="container-fluid w-100">
        <div className="d-flex align-items-center justify-content-between custom-mobile-class">
          <Link to="/" className="navbar-brand d-flex align-items-center" onClick={() => setExpanded(false)}>
            <svg width="100%" height="70" viewBox="0 0 323 101" fill="none" xmlns="http://www.w3.org/2000/svg" className='d-inline-block'>
              <path d="M100.025 50.0258C100.025 77.6545 77.6332 100.053 50.0123 100.053C22.3913 100.053 0 77.6545 0 50.0258C0 22.3985 22.3913 -0.000258447 50.0123 -0.000258447C77.6332 -0.000258447 100.025 22.3985 100.025 50.0258Z" fill="#003595"/>
                <path d="M125.906 73.6816H166.081V64.1365H125.906V73.6816Z" fill="#00AEEF"/>
                <path d="M135.678 54.4705H153.876V45.7081H135.678V36.1376H166.081V26.4987H125.906V54.4883H135.678V54.4705Z" fill="#00AEEF"/>
                <path d="M312.196 73.7344H323L319.003 64.136H308.306L312.196 73.7344Z" fill="#00AEEF"/>
                <path d="M270.557 73.7344H281.306L285.199 64.136H274.543L270.557 73.7344Z" fill="#00AEEF"/>
                <path d="M296.779 35.517L304.418 54.4883H314.984L303.347 26.5519H290.156L278.552 54.4883H289.086L296.779 35.517Z" fill="#00AEEF"/>
                <path d="M200.587 39.0461L219.673 55.1802H219.761L238.846 39.0461V55.1802H248.618V26.499H238.846L219.716 42.623L200.587 26.499H190.814V55.1802H200.587V39.0461Z" fill="#003595"/>
                <path d="M238.846 73.6816H248.618V64.1935H238.846V73.6816Z" fill="#003595"/>
                <path d="M190.815 73.6816H200.588V64.1935H190.815V73.6816Z" fill="#003595"/>
                <path d="M219.71 55.2128V55.2241L219.716 55.2178L219.724 55.2241V55.2128L219.76 55.1813H219.673L219.71 55.2128Z" fill="white"/>
                <path d="M59.2273 21.1502H32.0293V31.1779H45.2972V31.2147H56.5606C60.0149 31.2147 65.798 31.2147 65.798 41.0575C65.798 50.818 60.0149 50.818 56.5606 50.818H41.5997V50.8079H32.0293V79.0571H42.1163V60.88H59.2273C69.2726 60.88 75.7634 53.1004 75.7634 41.0575C75.7634 29.1501 69.1181 21.1502 59.2273 21.1502Z" fill="white"/>
                <path d="M50.1847 45.2471H32.0278V36.7418H50.1847V45.2471Z" fill="#00AEEF"/>
            </svg>
          </Link>

          <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" 
                  data-bs-target="#navbarNav" aria-controls="navbarNav" 
                  aria-expanded={expanded ? "true" : "false"} 
                  aria-label="Toggle navigation"
                  onClick={() => setExpanded(expanded ? !expanded : "expanded")}>
            <span className="navbar-toggler-icon ms-auto"></span>
          </button>
        </div>


        <div className={`collapse navbar-collapse ${expanded ? "show" : ""}`} id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" 
              data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><Link className="dropdown-item" to="/action1">Action 1</Link></li>
              <li><Link className="dropdown-item" to="/action2">Action 2</Link></li>
            </ul>
          </li>
            <Link className="nav-link" to="/edit" onClick={() => scrollToElement('home')}>{t('home:Navbar:item1')}</Link>
            <Link className="nav-link" to="/" onClick={() => scrollToElement('service')}>{t('home:Navbar:item2')}</Link>
            <Link className="nav-link" to="/edit">{t('navbar:modify')}</Link>
            <Link className="nav-link" to="/help">{t('navbar:help')}</Link>
            <ul className="navbar-nav">
              {i18n.language !== 'en' && (
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={() => changeLanguage('en')}>En</a>
                </li>
              )}
              {i18n.language !== 'fr' && (
                <li className="nav-item">
                  <a className="nav-link" href="#" onClick={() => changeLanguage('fr')}>Fr</a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav> */
